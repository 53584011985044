import css from 'styled-jsx/css';
import { desktop, phone, tablet_desktop } from '../../../styles/media';
import typography from '../../../styles/typography';

export const styles = css`
  .CollapsePanel_active .CollapsePanel__header_icon {
    transform: rotate(180deg);
  }
  .CollapsePanel__header_icon {
    font-size: 8px;
    margin-left: auto;
    margin-right: 0;
  }
  .CollapsePanel__header {
    position: relative;
    cursor: pointer;
    user-select: none;
    transition: 0.2s color ease-in-out;
    display: flex;
    align-items: center;
  }
  .CollapsePanel__header:hover {
    color: var(--uiColors-CollapsePanel-headerHoverText);
  }
  .CollapsePanel__label {
    ${typography.h6}
  }
  .CollapsePanel__badge {
    margin-left: 5px;
    font-size: 12px;
    color: var(--colors-darkenGrey1);
  }
  @media ${phone} {
    .CollapsePanel_active .CollapsePanel__header:after {
      top: 15px;
    }
    .CollapsePanel__header {
      margin: 0 -10px -1px;
      padding: 8px 40px 8px 10px;
      background-color: var(--colors-white);
      border-top: 1px solid var(--colors-brandLightGrey1);
      border-bottom: 1px solid var(--colors-brandLightGrey1);
    }
    .CollapsePanel__header:after {
      top: 16px;
      right: 13px;
    }
    .CollapsePanel__content {
      padding: 20px 0;
    }
  }
  @media ${tablet_desktop} {
    .CollapsePanel.active .CollapsePanel__header:after {
      top: 5px;
    }
    .CollapsePanel__header {
      margin-bottom: 10px;
      padding: 5px 30px 5px 0;
    }
    .CollapsePanel__header:after {
      top: 13px;
      right: 3px;
    }
  }
  @media ${desktop} {
    .CollapsePanel__header {
      padding: 14px 0 5px 0;
    }
  }
  `;
