import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import { Collapse } from 'react-collapse';
import getDataStatus from '../../../utils/system/getDataStatus';
import { styles } from './CollapsePanel.styles';

export interface CollapsePanelProps {
  label?: string
  badge?: string
  isCollapse?: boolean
  onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void
  children: ReactNode;
}

const CollapsePanel = (props: CollapsePanelProps) => {
  const { badge, isCollapse = true, label, onClick, children } = props;
  const [isOpen, setIsOpen] = useState<boolean>(isCollapse);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsOpen(!isOpen);

    if (onClick) onClick(event);
  };

  return (
      <div
        className={classNames('CollapsePanel', {
          CollapsePanel_active: isOpen,
        })}
        data-testid='collapse_panel'
        data-marker='Collapsible filter'
        date-status={getDataStatus(isOpen)}
      >
        <div
          className='CollapsePanel__header'
          onClick={handleClick}
        >
          {label && (
            <span className='CollapsePanel__label'>{label}</span>
          )}
          {badge && (
            <span className='CollapsePanel__badge'>{badge}</span>
          )}
          <span className='CollapsePanel__header_icon icon-caret-bottom'></span>
        </div>
        <Collapse
          isOpened={isOpen}
        >
          <div className='CollapsePanel__content'>{children}</div>
        </Collapse>

        <style jsx>{styles}</style>
      </div>
    );

};

export default CollapsePanel;
