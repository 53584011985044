export const clampValueToLimits = (
  value: number | null | undefined,
  min: number,
  max: number,
) => {
  if (value == null) {
    return min;
  }

  return Math.min(Math.max(min, value), max);
};
