import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import SliderField, { RangeNumbers } from 'src/components/ui-kit/SliderField/SliderField';
import { phone_tablet } from 'src/styles/media';
import { clampValueToLimits } from '../../../utils/js-helpers/clampValueToLimits';
import Input from '../Input/Input';
import { styles } from './FilterPrice.styles';
import Button from 'src/components/ui-kit/Button/Button';

const filterInputValue = (value: string) => {
  const filteredValue = value.replaceAll(/\D/g, '');

  return filteredValue? Number(filteredValue) : 0;
};
const inputValueToCoins = (value: string) => filterInputValue(value) * 100;

export interface FilterPriceProps {
  min: number;
  max: number;
  minValue: number;
  maxValue: number;
  onFilter(newFilterValue: RangeNumbers): void;
}

const FilterPrice = (props: FilterPriceProps) => {
  const { max, min, minValue, maxValue, onFilter } = props;
  const [rangeValue, setRangeValue] = useState<RangeNumbers>([minValue, maxValue]);
  const [inputMinValue, setInputMinValue] = useState<number>(minValue);
  const [inputMaxValue, setInputMaxValue] = useState<number>(maxValue);
  const step = Math.floor((max - min) / 10000) * 100 || 100;
  const isPhone = useMediaQuery({ query: phone_tablet });
  const rangeValueRef = useRef<RangeNumbers>();
  rangeValueRef.current = rangeValue;

  const setRangeValueToLocalStates = useCallback((rangeValue: RangeNumbers) => {
    setRangeValue(rangeValue);
    setInputMinValue(rangeValue[0]);
    setInputMaxValue(rangeValue[1]);
  }, []);

  useEffect(() => {
    const curFilterValue = rangeValueRef.current;
    // При изменении значения одной из пропс: min, max,
    // если входящее значение фильтра не совпадает с текущим,
    // пересчитать значения локальных состояний фильтра.
    // Это не обходимо для обработки удаления фильтра с другой части UI,
    // а так же, при изменении значений min и max в ответе сервера,
    // при взаимодействии пользователем с другими типами фильтров на UI.
    if(!isEqual([minValue, maxValue], curFilterValue)) {
      const newRangeValue: RangeNumbers = minValue && maxValue
        ? [minValue, maxValue]
        : [min, max];

      setRangeValueToLocalStates(newRangeValue);
    }
  }, [minValue, maxValue, min, max, setRangeValueToLocalStates]);

  const handleChangeThumbsPositions = (
    newRangeValue: RangeNumbers,
  ) => setRangeValueToLocalStates(newRangeValue);

  const getNewRangeValueFromInputs = (): RangeNumbers => {
    const newMinValue = clampValueToLimits(Math.min(inputMinValue, inputMaxValue), min, max);
    const newMaxValue = clampValueToLimits(Math.max(inputMinValue, inputMaxValue), min, max);

    return[newMinValue, newMaxValue];
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newRangeValue = getNewRangeValueFromInputs();
    setRangeValueToLocalStates(newRangeValue);

    if(!isEqual(newRangeValue, [minValue, maxValue])) {
      onFilter(newRangeValue);

      if(isPhone) {
        const back: HTMLButtonElement = document.querySelector('.CatalogFiltersMobileOptions__headerBackIcon');
        back?.click();
      }
    }
  };

  const handleInputMinChange = (value: string) => setInputMinValue(inputValueToCoins(value));

  const handleInputMaxChange = (value: string) => setInputMaxValue(inputValueToCoins(value));


  return (
    <form className='FilterPrice' onSubmit={handleSubmit}>
      <div className="FilterPrice__SliderFieldWrap">
        <SliderField min={min} max={max} step={step} value={rangeValue} onChange={handleChangeThumbsPositions}/>
      </div>

      <div className='FilterPrice__fieldsGrid'>
        <Input
          smallHeight
          dataTestId='FilterPrice__inputMin'
          dataMarkerValue='Filter Price Min'
          value={String(inputMinValue / 100)}
          onChange={handleInputMinChange}
          inputMode='numeric'
          smallPadding
        />

        <div className='FilterPrice__fieldsGridSeparator'/>

        <Input
          smallHeight
          dataTestId='FilterPrice__inputMax'
          dataMarkerValue='Filter Price Max'
          value={String(inputMaxValue / 100)}
          onChange={handleInputMaxChange}
          inputMode='numeric'
          smallPadding
        />

        <div className='FilterPrice__buttonOkWrap'>
          <Button
            variant='ghost'
            type='submit'
            dataMarkerValue='Filter Price OK'
          >
            OK
          </Button>
        </div>
      </div>

      <style jsx>{styles}</style>
    </form>
  );
};

export default FilterPrice;
