import { phone, tablet } from 'src/styles/media';
import css from 'styled-jsx/css';

export const styles = css`
    .FilterPrice {
      padding: 0 14px 0 0;
    }

    .FilterPrice__SliderFieldWrap {
      margin: 0 8px 16px;
    }

    .FilterPrice__fieldsGrid {
      display:flex;
      align-items: center;
    }

    .FilterPrice__fieldsGridSeparator {
      padding: 0 4px;
      font-size: 0;
      line-height: 0;
    }

    .FilterPrice__fieldsGridSeparator::after {
      content: '';
      display: inline-block;
      width: 10px;
      height: 1px;
      background-color: #303030;
    }

    .FilterPrice__buttonOkWrap {
      margin-left: 9px;
    }

    @media ${tablet} {
      .FilterPrice {
        padding: 32px 24px;
      }
      .FilterPrice__SliderFieldWrap {
        margin: 0 8px 43px;
      }

    }
    @media ${phone} {
      .FilterPrice {
        padding: 24px 10px;
      }
      .FilterPrice__SliderFieldWrap {
        margin: 0 8px 35px;
      }
    }
  `;
